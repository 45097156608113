import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import { NavLink } from "react-router-dom";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function Services() {
  const Site_Url = "https://namechangeinpancard.com/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //service API
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    const AllServices = async () => {
      const response = await fetch(`${BASE_URL}serviceAPI.php`);
      const jsonData = await response.json();
      setservicedata(jsonData);
    };
    AllServices();
  }, [BASE_URL]);

  // Banner Api
  const [bannerdata, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.ServiceMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ServiceMetaKeyword} />
        <meta name="description" content={PagemetaTags.ServiceMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Banner
        Bnanerimage={bannerdata.service_banner}
        image_title={bannerdata.service_banner_title}
        image_alt={bannerdata.service_banner_alt}
      />
      {/* Services Section Start */}
      <section className="services_section section_padding service_bg section_padding_bottom section_margin_top">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
            <div className="col-md-6">
              <div className="section_title mb-5">
                <h2 className="title text-light">{webSetting.service_title}</h2>
              </div>
            </div>
            <div className="col-md-3">
              <hr className="before_border devider_border_color" />
            </div>
          </div>
          <div className="mb-3 row">
            {servicedata.map((value, index) => (
              <div
                key={index}
                className="col-lg-2 col-md-4 col-sm-6 col-xs-6 mt-3"
              >
                <NavLink to="/services">
                  <div className="sevice_box">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.icon,
                      }}
                    ></div>
                    <h4>{value.name}</h4>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Services Section End */}

      {/* Content-3 Section Start */}
      {contentdata.title6 && (
        <DataContent
          title={contentdata.title6}
          desc={contentdata.description6}
        />
      )}

      {/* Content-3  Section End */}

      {/* How Work Section Start */}
      <section className="howWork_section section_padding section_padding_bottom ">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
            <div className="col-md-4">
              <div className="section_title mb-5">
                <h2 className="title text-dark">How It Works</h2>
              </div>
            </div>
            <div className="col-md-4">
              <hr className="before_border " />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="steps">
                <ul className="list-unstyled">
                  <div className="row">
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hand-o-up"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 1</span>
                              <h5>Select the Service</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-share-square-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 2</span>
                              <h5>Send Inquiry</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-question"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 3</span>
                              <h5>Ask Question</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block stepss askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-file-text-o"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 4</span>
                              <h5>Send Document</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li>
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-money"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 5</span>
                              <h5>Pay Online</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                    <div className="col-md-6">
                      <li className="last_staps6">
                        <div className="row">
                          <div className="col-md-12 d-flex">
                            <div className="d-inline-block askStapBox3">
                              <div className="askStap3">
                                <i
                                  className="fa fa-hourglass-start"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="d-inline-block step_one">
                              <span>Step 6</span>
                              <h5>Work Start</h5>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>

            <div className="col-md-4">
              <div className="img_right_box">
                <img
                  src={webSetting.howwork_img}
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* How Work Section End */}
    </>
  );
}
